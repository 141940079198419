
.back-btn {
    height: 32px !important;
    width: 88px;
    border-radius: 16px;
    border: 1px solid #B7B7BC;
    cursor: pointer;
    color: #40404D;
    background: #F6F6F9;
    margin-top: 8px;
}

.proof-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: #101020;
    line-height: 48px;
}

.proof-actor-level-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #40404D;
    margin-inline: 6px;
}

.proof-actor-level {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #40404D;
}

@media screen and (max-width: 576px) {
    .proof-card-main {
        display:inline-block;
        padding-top:24px;
        width:100%;
        background-color: #FFFFFF;
    }

    .proof-top {
        height: 48px;
        display: flex;
    }
    .proof-main {
        width: 100%;
        font-family: 'Montserrat' !important;
        font-style: normal !important;
    }
    .proof-context {
        padding: 20px 0px;
    }
    .proof-context-left {
        margin-bottom: 6px;
    }

    .proof-context-right {
        padding-top: 12px;
    }

    .proof-context-table {
        background-color: #FFFFFF;
        border-radius: 8px;
        padding: 24px;
        margin-bottom: 16px;
    }

    .proof-user {
        width: 100%;
        background-color: #FFFFFF;
        padding: 0px 16px 16px 16px;
        border-radius: 8px;
    }

    .proof-user-avatar {
        border-bottom: 1px dashed #B7B7BC;
        padding-top: 16px;
    }

    .proof-user-icon {
        background-color: #40404D;
        width: 100%;
        border-radius: 8px;
        padding: 12px;
    }

    .proof-user-title {
        padding-top: 0px;
    }

    .proof-user-title-x {
        display: flex;
        padding-top: 0px;
    }

    .proof-user-name {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        color: #101020;
        padding-top: 18px;
        float: left;
    }

    .proof-user-label {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        padding-top: 6px;
        color: #101020;
        float: right;
        text-align: right;
        line-height: 44px;
    }

    .proof-user-link {
        float: right;
        display: none;
        vertical-align: middle;
    }

    .proof-user-icon img {
        width: 100%;
        vertical-align: middle;
    }

    .proof-top-btn {
        float:left;
    }
    
    .proof-top-title {
        flex: 1;
        float:left;
        text-align: center;
        padding-right: 88px;
    }
}

@media screen and (min-width: 576px) {
    .proof-card-main {
        display:inline-block;
        width:100%;
        background-color: #FFFFFF;
    }
    .proof-top {
        height: 48px;
        display: flex;
    }
    .proof-main {
        width: 100%;
        padding-inline: 24px;
        font-family: 'Montserrat' !important;
        font-style: normal !important;
    }
    .proof-context {
        padding: 20px 0px;
        display: flex;
    }
    .proof-context-left {
        flex: 1;
        padding: 6px 12px 6px 0px;
    }
    .proof-context-right {
        flex: 1;
        padding: 6px 12px;
        padding: 6px 0px 6px 12px;
    }

    .proof-context-table {
        background-color: #FFFFFF;
        border-radius: 8px;
        padding: 4px 24px;
        margin-bottom: 24px;
    }

    .proof-user {
        width: 100%;
        min-height: 768px;
        background-color: #FFFFFF;
        padding: 0px 24px 24px;
        border-radius: 8px;
        
    }

    .proof-user-avatar {
        display: flex;
        max-height: 128px;
        padding-top: 24px;
        padding-bottom: 12px;
        border-bottom: 1px dashed #B7B7BC;
    }

    .proof-user-icon {
        display: flex;
        border-radius: 8px;
        padding: 3px;
        max-height: 92px;
        padding-inline: 24px;
    }

    .proof-user-icon img {
        height:auto;
        max-height: 86px;
        max-width: 250px;
        margin: auto;
    }

    .proof-user-title {
        flex: 1;
        padding-left: 20px;
        padding-top: 8px;
    }

    .proof-user-name {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        color: #101020;
        padding-bottom: 6px;
    }

    .proof-user-label {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        padding-top: 6px;
        padding-bottom: 6px;
        color: #101020;
    }

    .proof-user-link {
        float: right;
        padding-top: 20px;
        font-size: 20px;
    }

    .proof-top-btn {
        float:left;
    }
    
    .proof-top-title {
        flex: 1;
        float:left;
        text-align: center;
        padding-right: 88px;
    }

    .proof-card-main {
        display:inline-block;
        padding-top:24px;
        width:100%
    }

    .proof-user-title-x {
        display: flex;
        padding-top: 0px;
        vertical-align: middle;
    }
}

.proof-user-link span {
    cursor: pointer;
}