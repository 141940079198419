
@media screen and (max-width: 576px) {
    .detail-header {
        background-color: #FFFFFF;
        margin-top: 16px;
    }
    .detail-header-info {
        width: 100%;
        display: block;
    }
    
    .detail-header-info-title {
        color: #40404D;
        font-size: 16px;
        font-weight: 400;
        padding-block: 12px;
        display: block;
    }

    .detail-header-cell-title {
        color: #40404D;
        font-size: 16px;
        font-weight: 400;
        padding-block: 12px;
        width: 100%;
    }
    
    .detail-header-info-value {
        display: block;
        font-weight: 700;
        font-size: 16px;
        color: #101020;
        padding-block: 8px;
        margin-left: 28px;
        word-wrap: break-word;
        word-break: normal;
        max-width: 516px;
    }

    .proof-cell-grid {
        display: flex;
        flex-wrap: wrap;
        vertical-align: middle;
        text-align: center;
        max-height: 336px;
        overflow: auto;
    }
}

@media screen and (min-width: 576px) {

    .detail-header-cell-title {
        color: #40404D;
        font-size: 16px;
        font-weight: 400;
        padding-block: 12px;
        width: 100%;
    }

    .detail-header {
        background-color: #FFFFFF;
        margin-top: 16px;
    }
    .detail-header-info {
        width: 100%;
        display: flex;
    }
    .detail-header-info-title {
        color: #40404D;
        font-size: 16px;
        font-weight: 400;
        vertical-align: top;
        min-width: 35%;
        padding-block: 12px;
    }
    
    .detail-header-info-value {
        flex: 1;
        font-weight: 700;
        font-size: 16px;
        text-align: right;
        color: #101020;
        text-align: right;
        padding-block: 8px;
        word-wrap: break-word;
        word-break: normal;
    }

    .proof-cell-grid {
        display: flex;
        flex-wrap: wrap;
        vertical-align: middle;
        text-align: center;
        max-height: 336px;
        overflow: auto;
    }
}

.detail-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #40404D;
}

.proof-url-link {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-decoration-line: underline;
    color: #00913A;
}

.proof-cell {
    padding-left: 26px;
}

.proof-cell-td {
    width:40px;
    height:40px;
    background-color:#CCE9D8;
    margin-left:2px;
    margin-top: 2px;
    cursor: pointer;
    line-height: 40px;
    font-size: 12px;
}